




























import PatientData from '@/components/PatientData.vue';
import TestResultsTable from '@/components/tables/TestResultsTable.vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import TestResultComments from '@/components/TestResultComments.vue';
import {IBiomarkerResult} from '@/interfaces/biomarkerResults';
import {
  ICreatePhysicianComment,
  IRemovePhysicianComment,
  IRequisition,
  IReviewRequisition,
  ITestResult,
} from '@/interfaces/requisitions';
import ConfirmTestResultApproval from '@/components/modals/ConfirmTestResultApproval.vue';

import {IVisit} from '@/interfaces/visits';
import {dispatchUpdateRequisition, dispatchUpdateRequisitionNurse} from '@/store/crud/actions';
import {
  readHasNurseAccess,
  readHasPhysicianAccess,
  readHasReviewerAcccess,
  readUserProfile,
} from '@/store/main/getters';
import moment from 'moment-timezone';

@Component({
  components: {
    PatientData,
    TestResultsTable,
    TestResultComments,
    ConfirmTestResultApproval,
  },
})
export default class TestResults extends AppComponent {
  @Prop({type: Object}) public testResult: IRequisition | undefined;

  public testResults: ITestResult[] = [];
  public panelIndex: number[] = [0, 1, 2];
  public tableLoading: boolean = false;
  public comments: string = '';

  @Watch('testResult', {immediate: true})
  public onTestResultChanged(newValue: IRequisition, oldValue: IRequisition) {
    this.configureScreen();
  }

  @Watch('testResult.physicianNotes', {immediate: true})
  public onTestResultCommentsChanged(newValue: ICreatePhysicianComment[]) {
    if (newValue && newValue.length > 0) {
      this.panelIndex = [...this.panelIndex, 2];
    }
  }

  public get isNurse() {
    return readHasNurseAccess(this.$store);
  }

  public get userProfile() {
    return readUserProfile(this.$store);
  }

  public get isPhysician() {
    return readHasPhysicianAccess(this.$store);
  }

  public get isReviewer() {
    return readHasReviewerAcccess(this.$store);
  }

  /**
   * fetchs data asyncrhonously and
   * digests data. It show a loading spinner
   */
  public async configureScreen() {
    try {
      this.tableLoading = true;
      await this.$nextTick();
      await this.processData();
    } catch (ex) {
      this.toast('failed while configuring biomarker results', true);
      this.$router.back();
    } finally {
      this.tableLoading = false;
    }
  }

  /**
   * digests the testResults into a more friendly and readable format
   */
  public async processData() {
    this.testResults = [];
    const biomarkerResultsFromVisits: IBiomarkerResult[] = [];
    if (this.testResult && this.testResult.visits && this.testResult.visits.length > 0) {
      // get all biomarker results from all visits
      for (const visit of this.testResult.visits as IVisit[]) {
        if (
          visit.biomarkerResults &&
          Array.isArray(visit.biomarkerResults) &&
          visit.biomarkerResults.length > 0
        ) {
          biomarkerResultsFromVisits.push(...(visit.biomarkerResults as IBiomarkerResult[]));
        } else if (
          visit.biomarkerResults &&
          !Array.isArray(visit.biomarkerResults) &&
          Object.keys(visit.biomarkerResults).length > 0
        ) {
          biomarkerResultsFromVisits.push(visit.biomarkerResults as IBiomarkerResult);
        }
      }

      if (biomarkerResultsFromVisits && biomarkerResultsFromVisits.length > 0) {
        biomarkerResultsFromVisits.forEach((bResult) => {
          this.createTestResultItem(bResult);
        });
      }
    }
    return Promise.resolve();
  }

  /**
   * creates a test Result for each item in a friendly format
   * Each Biomarker should have sexSpecific information that should contain a "critical" structure.
   * This critical structure contains two flags: "isPriority1" and "isPriority2", if one of these flags
   * are true, the biomarker is considered critical.
   * also each result has a flag to indicate that the result is out of range because it exceeds the
   * normal values.
   */
  public createTestResultItem(item: IBiomarkerResult) {
    const expandedResult = {} as ITestResult;

    if (item.biomarker && item.biomarker.id) {
      const patientBiologicalSex = this.getPatientBiologicalSexFromRequisition(
        this.testResult as IRequisition,
      );

      const biomarkerSexDetailsForPatient = this.getBiomarkerSexDetailsForPatientBiologicalSex(
        item.biomarker,
        patientBiologicalSex,
      );

      const currentBiomarkerIsCritical = this.checkIfBiomarkerIsCritical(
        item.biomarker,
        patientBiologicalSex,
      );
      expandedResult.biomarkerCritical = currentBiomarkerIsCritical;
      expandedResult.isOutOfRange = item.testResultOutOfRange ? true : false;
      expandedResult.biomarkerCode = item.biomarker.questBiomarkerCode || '';
      expandedResult.biomarkerName = item.biomarker.name || '';
      // optimal ranges calculations
      if (biomarkerSexDetailsForPatient && Object.keys(biomarkerSexDetailsForPatient).length > 0) {
        if (
          biomarkerSexDetailsForPatient.optimalRangeHigh ||
          biomarkerSexDetailsForPatient.optimalRangeLow
        ) {
          if (
            biomarkerSexDetailsForPatient.optimalRangeHigh &&
            biomarkerSexDetailsForPatient.optimalRangeLow
          ) {
            expandedResult.optimalRanges = `${biomarkerSexDetailsForPatient.optimalRangeLow} - ${biomarkerSexDetailsForPatient.optimalRangeHigh}`;
          } else if (biomarkerSexDetailsForPatient.optimalRangeHigh) {
            expandedResult.optimalRanges = `${biomarkerSexDetailsForPatient.optimalRangeHigh}`;
          } else if (biomarkerSexDetailsForPatient.optimalRangeLow) {
            expandedResult.optimalRanges = `${biomarkerSexDetailsForPatient.optimalRangeLow}`;
          }
        } else {
          expandedResult.optimalRanges = '';
        }

        if (currentBiomarkerIsCritical) {
          expandedResult.isPriority1 = biomarkerSexDetailsForPatient.critical!.isPriority1
            ? true
            : false;
          expandedResult.isPriority2 = biomarkerSexDetailsForPatient.critical!.isPriority2
            ? true
            : false;
          expandedResult.priority1Range =
            biomarkerSexDetailsForPatient.critical!.priority1Range || '';
          expandedResult.priority2Range =
            biomarkerSexDetailsForPatient.critical!.priority2Range || '';

          /* Calculate if results are out of range */
          if (expandedResult.isOutOfRange && expandedResult.isPriority1) {
            expandedResult.isOutOfRangePriority1 = this.calculateOutOfRangeResult(
              expandedResult.priority1Range,
              item.testResult,
            );
          } else {
            expandedResult.isOutOfRangePriority1 = false;
          }
          if (expandedResult.isOutOfRange && expandedResult.isPriority2) {
            expandedResult.isOutOfRangePriority2 = this.calculateOutOfRangeResult(
              expandedResult.priority2Range,
              item.testResult,
            );
          } else {
            expandedResult.isOutOfRangePriority2 = false;
          }

          /**
           * Result is critical when biomarker is marked as critical
           * and is priority 1 or 2, also it should be out of range according to the resunt and priority ranges
           */
          if (currentBiomarkerIsCritical) {
            expandedResult.resultCritical =
              expandedResult.isOutOfRangePriority2 || expandedResult.isOutOfRangePriority1;
          }
        }
      }
    }

    expandedResult.biomarkerResult = (item.testResult as string) || '';
    expandedResult.biomarkerUnit = item.measurementUnits || '';
    expandedResult.range = item.questReferenceRange || '';

    this.testResults.push(expandedResult);
  }

  public get priority() {
    let priority = 0;
    // check if there is critical values
    if (this.testResults.some((result) => result.biomarkerCritical && result.resultCritical)) {
      // check if there is any priority 1 critical
      if (
        this.testResults.some(
          (result) =>
            result.biomarkerCritical && result.isOutOfRangePriority1 && result.isPriority1,
        )
      ) {
        priority = 1;
      } else {
        priority = 2;
      }
    } else {
      return false;
    }
    return priority;
  }

  /**
   * removes a comment from the array of comments
   */
  public removeComment(item) {
    if (!this.testResult) {
      return;
    }
    if (!item || !this.testResult.physicianNotes || this.testResult.physicianNotes.length === 0) {
      return;
    }

    const resultComments = {} as IRemovePhysicianComment;
    resultComments.physicianNotes = this.testResult.physicianNotes;
    resultComments.physicianNotes?.splice(resultComments.physicianNotes.indexOf(item), 1);

    this.saveComments(resultComments, true);
  }

  public commentUpdated(item, newComment) {
    if (!this.testResult) {
      return;
    }
    if (!item || !this.testResult.physicianNotes || this.testResult.physicianNotes.length === 0) {
      return;
    } else {
      const resultComments = {} as IRemovePhysicianComment;
      resultComments.physicianNotes = this.testResult.physicianNotes;
      // find the index of the comment that was updated and change the text

      let updatedComment = resultComments.physicianNotes?.find((comment) => comment === item);
      const temp = updatedComment?.split(' | ');

      if (temp && temp.length > 0) {
        temp[0] = newComment;
        updatedComment = temp.join(' | ');
      }

      resultComments.physicianNotes?.splice(
        resultComments.physicianNotes.indexOf(item),
        1,
        updatedComment || '',
      );

      this.saveComments(resultComments, false);
    }
  }

  /**
   * Saves a new comment into the array of comments
   * and send it to the server
   * Comments should be built in the following format:
   * "{actual comment input...} | {commenter user_id} | {timestamp} "
   * example :
   * “Mike, stop drinking so much coffee. | 9453-3963-3563-3653 | 21:34 12/12/2222”
   * @param submitComment - used to submit the comment to the server or not, default is true
   */
  public async createComment(commentToCreate: string | null = null) {
    const resultComments = {} as ICreatePhysicianComment;
    if (!this.comments && !commentToCreate) {
      this.toast('Please enter a comment', true);
      return;
    }

    // create a new date in iso format utc
    const date = moment().toISOString();
    // creates the comment string with the correct format
    const temp = `${commentToCreate ? commentToCreate : this.comments} | ${
      this.userProfile?.id
    } | ${date}`;

    // check if there is an array of comments, if not create one
    if (!!this.testResult?.physicianNotes?.length) {
      resultComments.physicianNotes = this.testResult.physicianNotes;
    } else {
      resultComments.physicianNotes = [];
    }
    // this should not be necessary but just in case
    // sanitize the comments that are empty in the array
    if (!!resultComments?.physicianNotes?.length) {
      resultComments.physicianNotes = resultComments.physicianNotes.filter(
        (comment) => comment !== '',
      );
    }

    // add the new comment to the array
    resultComments.physicianNotes?.push(temp);

    this.saveComments(resultComments);

    // emit that a new comment was created
    this.$emit('commentCreated', resultComments.physicianNotes);
    // clean the comment input
    this.comments = '';
    return Promise.resolve(resultComments);
  }

  public async saveComments(comments, remove = false) {
    if (!this.testResult) {
      return;
    }
    this.setAppLoading(true);

    let result: boolean = false;

    if (this.isNurse) {
      result = await dispatchUpdateRequisitionNurse(this.$store, {
        requisitionId: this.testResult.id!,
        requisition: comments,
      });
    } else {
      result = await dispatchUpdateRequisition(this.$store, {
        requisitionId: this.testResult.id!,
        requisition: comments,
      });
    }
    this.setAppLoading(false);

    if (result) {
      this.toast(!remove ? 'Comment saved!' : 'Comment deleted!', false);
    } else {
      this.toast('error saving comment', true);
    }
  }

  /**
   * Approves the test result, this will set the reviewed flag to true
   * It needs at least one comment to be able to approve the test result
   */
  public async approve(approveComment) {
    if (!approveComment) {
      this.toast('A comment is required in order to approve the test result', true);
      return;
    }

    // create the approve comment
    const commentsCreationResult = await this.createComment(approveComment);

    if (!commentsCreationResult) {
      this.toast('Unable to approve requisition', true);
      return;
    }

    if (!this.testResult || !this.testResult.id) {
      this.toast('Unable to approve requisition', true);
      return;
    }

    if (!this.isPhysician || this.isNurse || this.isReviewer) {
      this.toast('You are not authorized to approve this test result', true);
      return;
    }

    const approvedResult = {} as IReviewRequisition;
    approvedResult.reviewingPhysicianId = (this.userProfile?.id as string) || ('' as string);
    approvedResult.reviewed = true;
    const result: boolean = await dispatchUpdateRequisition(this.$store, {
      requisitionId: this.testResult.id,
      requisition: approvedResult,
    });

    if (result) {
      this.toast('Test result approved!', false);
      this.$emit('approved', this.testResult.id);
    } else {
      this.toast('Error while approving result', true);
    }
  }
}
